<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">
                                <router-link to="/message"><a href=""><i class="fa fa-arrow-left mr-3 text-dark"></i></a></router-link>
                                {{ tt('detail_message') }}
                            </h3>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="reply">{{ tt('reply_message') }}</base-button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div v-if="!onLoad">
                        <div v-for="message in message.data">
                            <div class="media align-items-center">
                            <span class="avatar avatar-sm rounded-circle">
                                <img alt="Image placeholder" :src="loadImage(message.image)">
                            </span>
                            <div class="media-body ml-2">
                                <span class="mb-0 text-sm font-weight-bold ml-1">{{message.name}}</span><br>
                                <a class="text-sm" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="ml--2 mt--2">
                                        <base-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a" :title="tt('to')" menu-classes="dropdown-menu-center py-0 overflow-hidden">
                                            <div class="dropdown-item">
                                                {{ tt('from') }} : {{message.name}}<br>
                                                {{ tt('to') }} : <template v-for="to in message.message_to">{{ to.user.name }}, &nbsp;</template>
                                            </div>
                                        </base-dropdown>
                                    </div>
                                </a>
                            </div>
                            <p style="font-size: 13px"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>{{message.reply_at}}</p>
                            </div>
                            <div class="ml-1">
                                <p class="text-dark" style="font-size: 13px; margin-left: 45px">{{message.message}}</p>
                            </div>
                            <hr>
                        </div>
                    </div>
                    <page-loading v-else/>
                </div>
            </div>
        </div>
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formReply.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('reply_message') }}</h5>
                </template>
                <div>     
                    <base-alert type="danger" v-if="errorMessage">
                        <p><strong>{{ errorMessage.message }}</strong></p>
                        <div v-if="errorMessage.data.length != 0">
                            <span v-for="message in errorMessage.data">
                                {{ message[0] }}<br>
                            </span>
                        </div>
                    </base-alert>               
                    <textarea class="form-control" rows="10" :placeholder="tt('message')" v-model="replyMessage"></textarea>                   
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" @click="sendReply()" :disabled="btnReply.onLoading">{{ tt('reply') }}</base-button>
                </template>
            </modal>
        </validation-observer>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import message from '@/services/message/message.service';
    import config from '@/configs/config';
    export default {        
        data() {
            return {                        
                onLoad: true,    
                btnReply: {
                    onLoading: false
                },         
                formReply: {
                    show: false
                },     
                errorMessage: null, 
                message: {
                    data: []
                },
                replyMessage: null
            }
        },
        mounted() {
            this.get();
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            get() {
                let context = this;                
                Api(context, message.getDetail(this.$route.params.id)).onSuccess(function(response){
                    context.message.data = response.data.data;
                    context.onLoad = false;
                }).onError(function(error) {                    
                    context.message.data = [];
                    context.onLoad = false;
                }).call();
            },
            reply() {
                this.formReply.show = true;
            },
            sendReply() {
                this.btnReply.onLoading = true;
                this.errorMessage = null;
                let context = this;                  
                Api(context, message.reply({'message_id': this.$route.params.id, 'reply': this.replyMessage})).onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formReply.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_delete'));
                    } else {
                        this.alertDialog('error', this.tt('error_delete'));
                    }
                })
            },
            loadImage(image) {
                return config.storageUrl+'images/user/'+image;
            },
            renderTo(tos) {
                let result = [];
                for (let i in tos) {
                    let to = tos[i];
                    if (to.user) {
                        result.push(to.user.first_name+' '+to.user.last_name);
                    }
                }                
                return result.join(', ');
            }
        }   
    };
</script>
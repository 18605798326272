import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('message/index?page=' + page, {params});
    },
    compose(params) {
        return Service().post('message/compose', params);
    },
    getDetail(id) {
        return Service().get('message/detail-message/'+id);
    },
    reply(params) {
        return Service().post('message/reply-message', params);
    },
    delete(id) {
        return Service().post('message/delete-inbox/'+id);
    },
}